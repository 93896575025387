<template>
  <div>
    <b-row>
      <b-col>
        <b-form-input
            id="input-log-title"
            v-model="title"
            placeholder="Task Title"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <v-md-editor v-model="description"
                       height="600px"
                       left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code"
          ></v-md-editor>
          <b-form-datepicker v-model="deadline" reset-button placeholder="Deadline"></b-form-datepicker>
          <b-card-footer>
            <b-button v-on:click="submitTask" variant="primary">Submit Task</b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import http from "../http-common"
import {DateTime} from "luxon";

export default {
  name: "EditTask",
  props: [
      'loggedIn',
  ],
  data: function () {
    return {
      description: "",
      title: "",
      deadline: null,
      id: null,
    };
  },
  methods: {
    submitTask() {
      if (this.id === null) {  // New Task
        http.post("/task/",
            {
              title: this.title,
              description: this.description,
              deadline: this.deadline,
            })
            .then((resp) => {
              this.$router.push(`/task/${resp.data.id}`);
            })
            .catch((err) => {
              http.std_error_handler(this, err);
            });
      } else {  // Edit Task
        http.put(`/task/${this.id}`,
            {
              title: this.title,
              description: this.description,
              deadline: this.deadline,
            })
            .then((resp) => {
              this.$router.push(`/task/${resp.data.id}`);
            })
            .catch((err) => {
              http.std_error_handler(this, err);
            });
      }
    }
  },
  computed: {
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
    let id = this.$route.params.id;
    console.log(id);
    if (id === undefined || id === null) {
      this.id = null;
      this.title = "";
      this.description = "";
      this.deadline = null;
    } else {
      http.get(`/task/${id}`)
          .then((resp) => {
            console.log(resp.data);
            this.id = resp.data.task.id;
            this.title = resp.data.task.title;
            this.description = resp.data.task.description;
            if (resp.data.task.deadline !== null) {
              this.deadline = DateTime.fromMillis(resp.data.task.deadline*1000).toISODate();
            } else {
              this.deadline = null;
            }
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
    }
  },
}
</script>

<style scoped>

</style>

<style>
.tab-label {
  color: rgba(150,150,160,1) !important;
}

.tab-label.active {
  color: rgba(50,50,50,1) !important;
}
</style>
